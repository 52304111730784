import { axios } from 'data';
import { Uuid } from 'types/common';
import { Client, ClientStatus, ClientVerificationDetails } from 'types/models';

import {
  ClientCreateParams,
  ClientsParams,
  ClientsResponse,
  ClientUpdateParams,
  ClientVerifyParams,
} from 'types/services';

import { prepareDateParam, preparePaginatableParams, preparePaginatedData } from './helpers';

const getClients = async (params: ClientsParams = {}) => {
  const response = await axios.get<ClientsResponse>('/core/admin/user-profile', {
    params: preparePaginatableParams(params),
  });

  return preparePaginatedData<Client>(response.data);
};

const getClient = async (id: Uuid) => {
  const response = await axios.get<Client>(`/core/admin/user-profile/${id}`);

  return response.data;
};

const getClientVerificationDetails = async (id: Uuid) => {
  const response = await axios.get<ClientVerificationDetails>(`/core/admin/user-profile/${id}/verification-details`);

  return response.data;
};

const createClient = async (params: ClientCreateParams) => {
  params = {
    ...params,
    birthdate: prepareDateParam(params.birthdate),
  };

  await axios.post<never>('/core/admin/user-profile/sign-up', params);
};

const updateClient = async (id: Uuid, params: Partial<ClientUpdateParams>) => {
  await axios.patch<never>(`/core/admin/user-profile/${id}`, params);
};

const activateClient = async (id: Uuid) => {
  await updateClient(id, { status: ClientStatus.ACTIVE });
};

const deactivateClient = async (id: Uuid) => {
  await updateClient(id, { status: ClientStatus.INACTIVE });
};

const verifyClient = async (id: Uuid, params: ClientVerifyParams) => {
  params = {
    ...params,
    dateOfBirth: prepareDateParam(params.dateOfBirth),
  };

  await axios.put<never>(`/core/admin/user-profile/${id}/verified`, params);
};

const unverifyClient = async (id: Uuid) => {
  await axios.put<never>(`/core/admin/user-profile/${id}/unverified`);
};

const resetClientPassword = async (id: Uuid) => {
  await axios.post<never>(`/core/admin/user-profile/${id}/set-up-temp-password`);
};

const clientService = {
  getClients,
  getClient,
  getClientVerificationDetails,
  createClient,
  activateClient,
  deactivateClient,
  verifyClient,
  unverifyClient,
  resetClientPassword,
};

export default clientService;
