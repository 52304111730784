import { axios } from 'data';
import { Uuid } from 'types/common';
import { FeeCommission } from 'types/models';
import { FeeCommissionParams, FeeCommissionsParams, FeeCommissionsResponse } from 'types/services';

import { prepareDateParam, preparePaginatableParams, preparePaginatedData, prepareTimezonableParams } from './helpers';

const getFeeCommissions = async (params: FeeCommissionsParams = {}) => {
  const response = await axios.get<FeeCommissionsResponse>('/payments/admin/fee-commission', {
    params: preparePaginatableParams(params),
  });

  return preparePaginatedData<FeeCommission>(response.data);
};

const getFeeCommission = async (id: Uuid) => {
  const response = await axios.get<FeeCommission>(`/payments/admin/fee-commission/${id}`);

  return response.data;
};

const createFeeCommission = async (params: FeeCommissionParams) => {
  params = {
    ...prepareTimezonableParams(params),
    startDate: params.startDate && prepareDateParam(params.startDate),
    endDate: params.endDate && prepareDateParam(params.endDate),
  };

  await axios.post<never>('/payments/admin/fee-commission', params);
};

const updateFeeCommission = async (id: Uuid, params: FeeCommissionParams) => {
  params = {
    ...prepareTimezonableParams(params),
    startDate: params.startDate && prepareDateParam(params.startDate),
    endDate: params.endDate && prepareDateParam(params.endDate),
  };

  await axios.patch<never>(`/payments/admin/fee-commission/${id}`, params);
};

const activateFeeCommission = async (id: Uuid) => {
  await axios.patch<never>(`/payments/admin/fee-commission/${id}/activate`);
};

const deactivateFeeCommission = async (id: Uuid) => {
  await axios.patch<never>(`/payments/admin/fee-commission/${id}/deactivate`);
};

const duplicateFeeCommission = async (id: Uuid) => {
  await axios.post<never>(`/payments/admin/fee-commission/${id}/copy`);
};

const deleteFeeCommission = async (id: Uuid) => {
  await axios.delete<never>(`/payments/admin/fee-commission/${id}`);
};

const feeCommissionService = {
  getFeeCommissions,
  getFeeCommission,
  createFeeCommission,
  updateFeeCommission,
  activateFeeCommission,
  deactivateFeeCommission,
  duplicateFeeCommission,
  deleteFeeCommission,
};

export default feeCommissionService;
